import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { JaneDM, SmartSort } from '@iheartjane/dm-sdk';
import { ProductsData } from '@cura/next-server/GetProducts';
import Cookies from 'js-cookie';

import { DatalayerAnalytics } from 'services';
import { AccountBanner } from '../components/Account/Banner';
import { PageState } from '../components/PageState';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { StorefrontPageProps } from './storefrontPage';
import {
  StorefrontCarousel,
  StorefrontCarouselProps
} from '../components/StorefrontCarousel';
import { StorefrontBanners } from '../components/StorefrontBanners';
import { WideBanner } from '../components/WideBanner';
import { productsAnalyticsMap } from '../utils/storefrontCarouselsMap';
import { shopHomeJsonLD } from '../utils/jsonLD';
import { moodiDayScript } from '../utils/moodiDayScript';
import { OverviewCard } from '../components/DispensaryOverview/OverviewCard';
import { PackageComponentWrapper } from '../components/PackageComponentWrapper';

export function getJdid() {
  const jdid = Cookies.get('jdid');

  if (jdid) {
    return jdid;
  }

  const newJdid = JaneDM.generateJaneDeviceId();
  Cookies.set('jdid', newJdid, {
    expires: 30
  });
  return newJdid;
}

const ihjClient = new JaneDM({
  apiKey: process.env.NEXT_PUBLIC_IHJ_API_KEY || '',
  endpoint: process.env.NEXT_PUBLIC_IHJ_ENDPOINT,
  identifier: { jdid: getJdid() }
});

type HomePageProps = Omit<StorefrontPageProps, 'formConfiguration'>;

const NoSSR_StorefrontCarousel = dynamic(
  () =>
    import('../components/StorefrontCarousel').then(
      (mod) => mod.StorefrontCarousel
    ),
  { ssr: false }
);

export const DutchiePlusHomePage = ({
  productLists,
  storefrontBanners
}: HomePageProps) => {
  const {
    currentPathDispensary,
    defaultMenuType,
    favoredBrands,
    offerList,
    staticCategories
  } = useDispensaryPathContext();
  const { addItemToCart, isMobile, websiteUrl, width } = useSiteWideContext();
  const [showWideBanner, setShowWideBanner] = useState(true);
  const [sponsoredProducts, setSponsoredProducts] =
    useState<StorefrontCarouselProps>();
  const [topOfMenuProducts, setTopOfMenuProducts] =
    useState<StorefrontCarouselProps>();

  useEffect(() => {
    if (currentPathDispensary?.location?.state) {
      DatalayerAnalytics.pushPageView({
        page_type: 'shop/home',
        consolidateDispensary: currentPathDispensary
      });
    }
  }, []);

  useEffect(() => {
    if (
      !process.env.NEXT_PUBLIC_IHJ_API_KEY ||
      !currentPathDispensary.sponsoredIHJStoreId
    ) {
      return;
    }

    ihjClient
      .fetchMenuTopRow({
        storeId: currentPathDispensary.sponsoredIHJStoreId,
        searchAttributes: ['*'],
        searchOptionalFilters:
          'brand:Grassroots<score=9>,brand:JAMS<score=8>,brand:Select<score=7>,brand:FIND<score=6>,brand:Zero Proof<score=5>,brand:Endless Coast<score=4>,brand:Curaleaf<score=3>'
      })
      .then(async (placement: SmartSort) => {
        const sponsoredProdIds: string[] = [];
        placement.products.forEach((prod) => {
          const posIdObj = prod.attributes.pos_product_lookup;
          Object.keys(posIdObj).forEach((key) => {
            sponsoredProdIds.push(posIdObj[key]);
          });
        });
        if (sponsoredProdIds.length) {
          await fetch(
            `${websiteUrl}/api/products/${currentPathDispensary.retailerId}?menuType=${defaultMenuType}&pageType=HomePage&sponsoredIds=${sponsoredProdIds.join(',')}&dispensaryUid=${currentPathDispensary.uid}`
          )
            .then((res) => res.json())
            .then(({ data }: { data: ProductsData }) => {
              if (data.products?.length) {
                setTopOfMenuProducts({
                  banners: productsAnalyticsMap(
                    addItemToCart,
                    data.products,
                    undefined,
                    placement.products
                  ),
                  isLoading: false,
                  title: placement.title || 'Sponsored Products',
                  type: 'products'
                });
              }
            })
            .catch((error) => {
              DatalayerAnalytics.pushErrorEvent({
                category: 'api',
                location: 'fetchProductsMenuTopRow',
                description:
                  (error as string) ||
                  'Error fetching products for menu top row.'
              });
            });
        }
      })
      .catch((err: any) => {
        console.error('err', err);
      });
  }, []);

  useEffect(() => {
    if (
      !process.env.NEXT_PUBLIC_IHJ_API_KEY ||
      !currentPathDispensary.sponsoredIHJStoreId
    ) {
      return;
    }

    ihjClient
      .fetchRecommendedRow({
        storeId: currentPathDispensary.sponsoredIHJStoreId,
        searchAttributes: ['*'],
        searchOptionalFilters:
          'brand:Grassroots<score=9>,brand:JAMS<score=8>,brand:Select<score=7>,brand:FIND<score=6>,brand:Zero Proof<score=5>,brand:Endless Coast<score=4>,brand:Curaleaf<score=3>'
      })
      .then(async (placement: SmartSort) => {
        const sponsoredProdIds: string[] = [];
        placement.products.forEach((prod) => {
          const posIdObj = prod.attributes.pos_product_lookup;
          Object.keys(posIdObj).forEach((key) => {
            sponsoredProdIds.push(posIdObj[key]);
          });
        });
        if (sponsoredProdIds.length) {
          await fetch(
            `${websiteUrl}/api/products/${currentPathDispensary.retailerId}?menuType=${defaultMenuType}&pageType=HomePage&sponsoredIds=${sponsoredProdIds.join(',')}&dispensaryUid=${currentPathDispensary.uid}`
          )
            .then((res) => res.json())
            .then(({ data }: { data: ProductsData }) => {
              if (data.products?.length) {
                setSponsoredProducts({
                  banners: productsAnalyticsMap(
                    addItemToCart,
                    data.products,
                    undefined,
                    placement.products
                  ),
                  isLoading: false,
                  title: placement.title || 'Popular Products',
                  type: 'products'
                });
              }
            })
            .catch((error) => {
              DatalayerAnalytics.pushErrorEvent({
                category: 'api',
                location: 'fetchProductsForSponsored',
                description:
                  (error as string) || 'Error fetching products for sponsored.'
              });
            });
        }
      })
      .catch((err: any) => {
        console.error('err', err);
      });
  }, []);

  useEffect(() => {
    // MOODIDAY after products load in
    moodiDayScript();
  }, []);

  if (!productLists[defaultMenuType].length) {
    DatalayerAnalytics.pushErrorEvent({
      category: 'expected',
      location: 'shopHomePage',
      description: 'No products found',
      consolidateDispensary: currentPathDispensary
    });
    return (
      <PageState
        header="Products not available."
        link={{
          url: '/locations',
          text: 'Check out our other locations!'
        }}
      />
    );
  }

  return (
    <>
      {process.env.IS_KIOSK !== 'true' ? (
        <section>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                shopHomeJsonLD(currentPathDispensary.shopLink)
              )
            }}
          />
        </section>
      ) : null}
      <div className="d-flex flex-column gap-24 margin-bottom-32">
        {showWideBanner && currentPathDispensary.storefrontMap ? (
          <WideBanner
            widebanner={currentPathDispensary.storefrontMap.ctaBanners}
            onClose={() => {
              setShowWideBanner(false);
            }}
          />
        ) : null}
        <PackageComponentWrapper additionalClass="margin-top-32">
          <div className="d-flex flex-column-tablet">
            {!isMobile ? (
              <OverviewCard dispensary={currentPathDispensary} width={width} />
            ) : null}
            <StorefrontBanners
              props={{
                isLoading: false,
                banners: storefrontBanners
              }}
            />
          </div>
        </PackageComponentWrapper>
        {process.env.IS_KIOSK !== 'true' ? <AccountBanner /> : null}
        <PackageComponentWrapper childrenClass="d-flex flex-column gap-24 container-lr">
          <div id="categories">
            <StorefrontCarousel
              banners={staticCategories[defaultMenuType]}
              isLoading={false}
              title="Categories"
              type="categories"
            />
          </div>
          <div id="brands">
            <StorefrontCarousel
              banners={favoredBrands.map((fb) => ({
                ...fb,
                url: `${currentPathDispensary.shopLink}/brands/${fb.slug}`
              }))}
              isLoading={false}
              title="Our Brands"
              type="brands"
            />
          </div>
          {topOfMenuProducts ? (
            <StorefrontCarousel {...topOfMenuProducts} />
          ) : null}
          <div id="specials">
            <StorefrontCarousel
              banners={offerList}
              isLoading={false}
              title="Specials"
              type="specials"
              link={`${currentPathDispensary.shopLink}/specials`}
            />
          </div>
          {sponsoredProducts ? (
            <StorefrontCarousel {...sponsoredProducts} />
          ) : null}
          {/* SSR disabled, to get rid of prerendering error due to menu swapping */}
          {(
            productLists[defaultMenuType] || [
              { title: '', link: '', products: [] }
            ]
          ).map((p) => {
            return (
              <NoSSR_StorefrontCarousel
                key={p.title}
                banners={productsAnalyticsMap(addItemToCart, p.products)}
                isLoading={false}
                link={p.link}
                title={p.title}
                type="products"
              />
            );
          })}
        </PackageComponentWrapper>
      </div>
    </>
  );
};
