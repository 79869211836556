'use client';

import Cookies from 'js-cookie';
import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { usePathname, useRouter } from 'next/navigation';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import {
  AccountMode,
  AuthContextTypes,
  AuthUser,
  Consolidate_Cart,
  Consolidate_Dispensary,
  Consolidate_ProductVariant,
  Curaql_OrderType,
  Curaql_PricingType,
  Curaql,
  DatalayerAnalytics,
  GoogleGeoIp,
  SiteWideDispensary,
  StoreCardDispensary,
  DutchiePlus_PricingType
} from 'services';
import useWindowDimensions from '../useWindowDimensions';
import { ProductListTilePopupProps } from '../../components/ProductListTile';
import { deliveryTypeCheck } from '../../utils/ecommerce';
import { UseMutationResult } from '@tanstack/react-query';
import {
  AddToCartProps,
  AxiosData,
  MergeCartData,
  RemoveFromCartData,
  RemoveFromCartProps,
  UpdateOrderTypeData,
  UpdateOrderTypeProps,
  useCartData
} from '../graphs';
import { apolloCartToConsolidateCartMap, optInBoolean } from './helpers';
import { AuthDrawer } from '../../components/Account/Drawer/auth';
import { CookieAcceptanceBanner } from '../../components/CookieAcceptanceBanner';
import { fetchDispoCategories } from '../../utils/fetchDispoCategories';

type PopupProduct = {
  product: ProductListTilePopupProps['item']['product'];
  variant: Consolidate_ProductVariant;
  quantity: number;
};

export type CategoryLink = {
  key: string;
  title: string;
  link: string;
};

type CartContextType = {
  addItemToCart?: (variables: AddToCartProps, cb: () => void) => void;
  cart: {
    data: Consolidate_Cart | undefined;
    loading: boolean;
    error: Error | null;
    refetch: () => void;
  };
  checkoutId: string;
  clearAnonCart?: () => void;
  clearCart?: (cb?: () => void) => Promise<void>;
  mergeCart?: UseMutationResult<AxiosData<MergeCartData>, any, void, unknown>;
  quantity: number;
  removeItemFromCart?: UseMutationResult<
    AxiosData<RemoveFromCartData>,
    any,
    RemoveFromCartProps,
    unknown
  >;
  updateCartOrderType?: UseMutationResult<
    AxiosData<UpdateOrderTypeData>,
    any,
    UpdateOrderTypeProps,
    unknown
  >;
};

export type SiteWideWrapperProps = {
  clearSelectedDispensaryIDForKiosk: () => void;
  userMenuType: Consolidate_Dispensary['menuTypes'][number];
  setUserMenuType: Dispatch<Consolidate_Dispensary['menuTypes'][number]>;
  userOrderType: Curaql_OrderType;
  setUserOrderType: Dispatch<Curaql_OrderType>;
  selectedDispensary?: SiteWideDispensary | null;
  selectedDispensaryID: string;
  setSelectedDispensary: Dispatch<string>;
  setSelectedDispensaryID: Dispatch<string>;
  selectedDispensaryLoading: boolean;
  isMobile: boolean;
  isTablet: boolean;
  width?: number;
  categories: {
    data: CategoryLink[];
    loading: boolean;
  };
  popup: {
    show: boolean;
    product: PopupProduct | null;
    setProduct: Dispatch<PopupProduct>;
    setShow: (bool: boolean) => void;
  };
  websiteUrl: string;
} & AuthContextTypes &
  CartContextType;

export const SiteWideContext = createContext<SiteWideWrapperProps>(
  {} as SiteWideWrapperProps
);

interface SiteWideContextProviderProps {
  children: ReactNode;
  contextProps: {
    isPreview: boolean;
  };
}

const CART_META_KEY = Curaql.CART_META_KEY;

export const SiteWideContextProvider = ({
  children,
  contextProps
}: SiteWideContextProviderProps) => {
  const pathname = usePathname();
  const websiteUrl =
    typeof window !== 'undefined' ? window.location.origin : '';

  /* ================== AUTH CONTEXT ================== */
  const [user, setUser] = useState<AuthUser>({
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    birthday: '',
    state: '',
    optIn: false,
    optInSms: false
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const [accountDrawerVisible, setAccountDrawerVisible] = useState(false);
  const [accountDrawerMode, setAccountDrawerMode] =
    useState<AccountMode>('login');
  const [accountDrawerUserEmail, setAccountDrawerUserEmail] =
    useState<string>();
  const [loading, setLoading] = useState(true);

  const tokenCookie = Cookies.get('curaleafAccountId');

  const clearUser = () => {
    setUser({
      _id: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      birthday: '',
      state: '',
      optIn: false,
      optInSms: false
    });
    setIsLoggedIn(false);
    Cookies.remove('curaleafAccountId', {
      domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : undefined
    });
    Cookies.remove('category_preferences', {
      domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : undefined
    });
    Cookies.remove('categoryViewpreferences', {
      domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : undefined
    });
    if (typeof window !== 'undefined') {
      window.curaleaf_id = '';
    }
  };

  const checkUser = async () => {
    setLoading(true);
    return await axios({
      method: 'GET',
      withCredentials: true,
      url: `${process.env.AUTH_URL}/api/auth/v1/isAuthenticated`,
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 401
    }).then(async (res) => {
      if (res.data == 'Authenticated') {
        await axios({
          method: 'GET',
          withCredentials: true,
          url: `${process.env.AUTH_URL}/api/auth/v1/user`
        })
          .then(async (res: AxiosResponse) => {
            if (res.data) {
              const user = res.data;
              setUser({
                ...user,
                optIn: user.mainSubscriptionStatus
                  ? user.mainSubscriptionStatus === 'opted_in'
                  : user.marketingOptIn
              });
              setIsLoggedIn(true);
              setTimestamp(moment().valueOf() + 5 * 60000);
              // This is for customer matching
              if (typeof window !== 'undefined') {
                window.curaleaf_id = user.curaleafId;
              }
            }
          })
          .catch((err) => {
            clearUser();
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        clearUser();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (process.env.IS_KIOSK !== 'true') {
      if (tokenCookie && moment().valueOf() > timestamp) {
        checkUser();
      } else {
        setLoading(false);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (process.env.IS_KIOSK !== 'true') {
      if (tokenCookie) {
        checkUser();
      } else {
        setLoading(false);
      }
    }
  }, [tokenCookie]);

  const intitiateAuthHandoff = async (token: string) => {
    const queryParams = new URLSearchParams(location.search);
    const orderType = queryParams.get('orderType');
    return await axios({
      method: 'GET',
      url: `${process.env.AUTH_URL}/api/auth/v1/consume-handoff-token`,
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 401,
      headers: {
        'x-cura-handoff': token
      },
      withCredentials: true
    }).then(async (res) => {
      Cookies.set(
        'curaleafAccountId',
        res.data.curaleafAccountId,
        process.env.COOKIE_DOMAIN
          ? {
              domain: process.env.COOKIE_DOMAIN
            }
          : undefined
      );
      // sets local storage to prevent popups
      process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct')
        ? window.sessionStorage.getItem('confirmed21OrOlder')
        : window.localStorage.getItem('confirmed21OrOlder');
      window.localStorage.setItem(
        'promptedForMailingList',
        JSON.stringify(Date.now())
      );

      updateUserOrderTypeLocalStorage(orderType as Curaql_OrderType);

      const user = await axios.get(`${process.env.AUTH_URL}/api/auth/v1/user`, {
        withCredentials: true
      });
      if (user) {
        // Note: This string is split downstream for the id value
        const localCart = Cookies.get(CART_META_KEY);
        if (
          (localCart &&
            JSON.parse(localCart).items &&
            user.data.cart?.items?.length &&
            parseInt(JSON.parse(localCart).updatedAt) <
              parseInt(user.data.cart.updatedAt)) ||
          (user.data.cart && (!localCart || !JSON.parse(localCart).items))
        ) {
          Cookies.set(
            CART_META_KEY,
            JSON.stringify({
              checkoutId: user.data.cart.id,
              dispensaryUniqueId: user.data.cart.dispensaryUniqueId,
              menuType: user.data.cart.pricingType,
              redirectUrl: user.data.cart.redirectUrl,
              updatedAt: user.data.cart.updatedAt
            })
          );
        }

        // TODO: refactor to accomodate form after braze integration
        setUser({
          _id: user.data._id,
          email: user.data.email,
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          phone: user.data.phone,
          birthday: user.data.birthday,
          state: user.data.state,
          optIn: user.data.mainSubscriptionStatus
            ? optInBoolean(user.data.mainSubscriptionStatus)
            : user.data.marketingOptIn,
          optInSms: !!user.data.smsSubscriptionStatus
        });
      }
    });
  };

  /* ================== SITE WIDE CONTEXT ================== */
  const { width } = useWindowDimensions();
  const { refresh } = useRouter();

  /* ----- USER ORDER TYPE ----- */
  const [userOrderType, setUserOrderType] = useState<Curaql_OrderType>(
    typeof window !== 'undefined' && window.localStorage.getItem('orderType')
      ? (window.localStorage.getItem('orderType') as Curaql_OrderType)
      : 'PICKUP'
  );
  const updateUserOrderTypeLocalStorage = (type: Curaql_OrderType) => {
    // couldn't rely on just useState because we need to set local storage
    window.localStorage.setItem('orderType', type);
    setUserOrderType(type);
  };

  /* ----- SELECTED DISPENSARY/ID ----- */
  const [selectedDispensary, updateSelectedDispensary] = useState<
    SiteWideDispensary | undefined | null
  >();
  const [selectedDispensaryLoading, setDispensaryLoading] =
    useState<boolean>(true);

  const storageSelectedDispensaryID =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('selectedDispensaryId')
      : '';
  const selectedDispensaryID = storageSelectedDispensaryID
    ? storageSelectedDispensaryID
    : '';

  const setSelectedDispensaryID = (uid: string) => {
    window.localStorage.setItem('selectedDispensaryId', uid);
  };

  const clearSelectedDispensaryID = () => {
    window.localStorage.removeItem('selectedDispensaryId');
    updateSelectedDispensary(null);
  };

  const getDispoFromIPGeo = async () => {
    if (process.env.ENABLE_IP_GEO !== 'true') {
      return;
    }
    await GoogleGeoIp.getGoogleCoords().then(async (res) => {
      const { location } = res;
      if (location && location.lat && location.lng) {
        const googleCoords = {
          latitude: location.lat,
          longitude: location.lng
        };
        await fetch(
          `${websiteUrl}/api/dispensaries/store-drawer?forDefaultStore=true&coordinates=${JSON.stringify(
            googleCoords
          )}&isPreview=${contextProps.isPreview}`,
          { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
        )
          .then((res) => res.json())
          .then(
            async ({ data }: { data: Pick<StoreCardDispensary, 'uid'> }) => {
              if (data?.uid) {
                setSelectedDispensary(data.uid);
                setDispensaryLoading(false);
              } else {
                return;
              }
            }
          )
          .catch((error) => {
            DatalayerAnalytics.pushErrorEvent({
              category: 'api',
              location: 'getDispoFromIPGeo',
              description:
                (error as string) ||
                'Error getting dispensary using IP in sitewide context.',
              consolidateDispensary: selectedDispensary as SiteWideDispensary
            });
          });
      } else {
        return;
      }
    });
  };

  useEffect(() => {
    if (
      (selectedDispensaryID && !selectedDispensary) ||
      (selectedDispensaryID &&
        selectedDispensary &&
        selectedDispensaryID !== selectedDispensary.uid)
    ) {
      // get selected dispensary if there is a UID in local storage
      getSelectedDispensaryInfo(selectedDispensaryID);
    } else if (process.env.IS_KIOSK !== 'true' && !selectedDispensaryID) {
      setDispensaryLoading(true);
      // get selected dispensary based on geolocation
      navigator.permissions?.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          // fetch dispensary based on geolocation coordinates
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const coords = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };
              if (coords.latitude && coords.longitude) {
                fetch(
                  `${websiteUrl}/api/dispensaries/store-drawer?forDefaultStore=true&coordinates=${JSON.stringify(
                    coords
                  )}&isPreview=${contextProps.isPreview}`,
                  { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
                )
                  .then((res) => res.json())
                  .then(
                    ({ data }: { data: Pick<StoreCardDispensary, 'uid'> }) => {
                      if (data?.uid) {
                        setSelectedDispensary(data.uid);
                        setDispensaryLoading(false);
                      } else {
                        getDispoFromIPGeo();
                      }
                    }
                  )
                  .catch((error) => {
                    DatalayerAnalytics.pushErrorEvent({
                      category: 'api',
                      location: 'packages/ui/hooks/siteWideContext/index.tsx',
                      description:
                        (error as string) ||
                        'Error fetching selected dispensary in Sitewide Context useEffect',
                      consolidateDispensary:
                        selectedDispensary as SiteWideDispensary
                    });
                  });
              } else {
                getDispoFromIPGeo();
              }
            },
            getDispoFromIPGeo,
            {
              timeout: 5000
            }
          );
        } else {
          getDispoFromIPGeo();
        }
      });
    }
    setDispensaryLoading(false);
  }, [selectedDispensaryID, selectedDispensary]);

  const getSelectedDispensaryInfo = (uid: string) => {
    if (uid) {
      setDispensaryLoading(true);
      fetch(`${websiteUrl}/api/dispensaries/${uid}`, {
        next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 }
      })
        .then((res) => res.json())
        .then(({ data }: { data: SiteWideDispensary }) => {
          const isStateSite =
            process.env.CONTENTSTACK_ENVIRONMENT.includes('ct') ||
            process.env.CONTENTSTACK_ENVIRONMENT.includes('nd');
          if (isStateSite) {
            const envStateAbbr =
              process.env.CONTENTSTACK_ENVIRONMENT?.split(
                '-'
              )[0]?.toUpperCase();
            if (envStateAbbr === data.location.state.abbreviation) {
              updateSelectedDispensary(data);
              if (categories?.dispensaryId !== uid) {
                getCategories(data);
              }
            } else {
              clearSelectedDispensaryID();
            }
          } else {
            if (
              data &&
              ((data.location?.state.abbreviation !== 'CT' &&
                data.location?.state.abbreviation !== 'ND') ||
                process.env.IS_KIOSK === 'true')
            ) {
              updateSelectedDispensary(data);
              if (categories?.dispensaryId !== uid) {
                getCategories(data);
              }
            } else {
              clearSelectedDispensaryID();
            }
          }
        })
        .catch((error) => {
          DatalayerAnalytics.pushErrorEvent({
            category: 'api',
            location: 'getSelectedDispensaryInfo',
            description:
              (error as string) ||
              'Get Selected Dispensary in Sitewide Context Error',
            consolidateDispensary: selectedDispensary as SiteWideDispensary
          });
        })
        .finally(() => setDispensaryLoading(false));
    }
  };

  const setSelectedDispensary = (uid: string) => {
    setSelectedDispensaryID(uid);
    if (!selectedDispensary || (uid && uid !== selectedDispensary.uid)) {
      if (selectedDispensaryID !== selectedDispensary?.uid) {
        getSelectedDispensaryInfo(uid);
      }
    }
  };

  /* -----  USER MENU/PRICING TYPE ----- */
  let storageUserMenuType =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('pricingType')
      : '';

  const userMenuType: DutchiePlus_PricingType =
    storageUserMenuType &&
    (storageUserMenuType === 'RECREATIONAL' ||
      storageUserMenuType === 'MEDICAL')
      ? storageUserMenuType
      : 'RECREATIONAL';

  const setUserMenuType = (type: Curaql_PricingType) => {
    window.localStorage.setItem('pricingType', type);
    // todo clear cart if available ???
    if (userOrderType === 'DELIVERY' && selectedDispensary) {
      const hasDelivery = deliveryTypeCheck(
        selectedDispensary.orderTypes,
        userMenuType
      );
      setUserOrderType(hasDelivery ? 'PICKUP' : userOrderType);
    }
    refresh();
  };

  useEffect(() => {
    storageUserMenuType =
      typeof window !== 'undefined'
        ? window.localStorage.getItem('pricingType')
        : '';
    if (userMenuType !== storageUserMenuType) {
      setUserMenuType(userMenuType);
      if (selectedDispensary) getCategories(selectedDispensary);
    }
  }, [userMenuType, storageUserMenuType]);

  /* ----- CATEGORIES ----- */
  const [categories, setCategories] = useState<
    | {
        categories: CategoryLink[];
        dispensaryId: string;
      }
    | undefined
  >();
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const getCategories = (selectedDispensary: SiteWideDispensary) => {
    setCategoriesLoading(true);
    const hasBothTypes = selectedDispensary.menuTypes.length === 2;
    fetchDispoCategories(
      selectedDispensary,
      hasBothTypes
        ? userMenuType
        : selectedDispensary.menuTypes[0] || 'RECREATIONAL',
      contextProps.isPreview,
      setCategoriesLoading,
      setCategories
    );
  };
  /* ----- PRODUCT POPUP ----- */
  const [showPopup, togglePopup] = useState<boolean>(false);
  const [popupProduct, setPopupProduct] = useState<PopupProduct | null>(null);

  const setShowPopup = (bool: boolean) => {
    if (!pathname.includes('/cart')) {
      togglePopup(bool);
      if (bool) {
        setTimeout(() => {
          togglePopup(false);
          setPopupProduct(null);
        }, 7000);
      }
    }
  };

  const {
    addItemToCart,
    cart,
    clearAnonCart,
    clearCart,
    mergeCart,
    removeItemFromCart,
    updateOrderType: updateCartOrderType
  } = useCartData({
    isLoggedIn,
    setPopupProduct,
    setShowPopup,
    selectedDispensary,
    selectedDispensaryID,
    selectedDispensaryLoading,
    updateUserOrderTypeLocalStorage,
    user,
    userMenuType
  });

  /* ----- SET CART COOKIES ----- */
  useEffect(() => {
    const cartCookie = Cookies.get(CART_META_KEY);
    if (cartCookie && selectedDispensary && !selectedDispensaryLoading) {
      const parsedCartCookie = JSON.parse(cartCookie);
      const cartDispo = parsedCartCookie.dispensaryUniqueId;
      if (cartDispo !== selectedDispensary.uid) {
        Cookies.remove(CART_META_KEY);
      }
    }
  }, [userMenuType, selectedDispensaryLoading, selectedDispensary]);

  /* ------ CART META DATA ------ */
  const cartMeta = Cookies.get(Curaql.CART_META_KEY);
  const parsedCartMeta =
    cartMeta && JSON.parse(cartMeta) ? JSON.parse(cartMeta) : null;
  const checkoutId = parsedCartMeta?.checkoutId || '';

  /* ------ QUANTITY ------ */
  let quantity = 0;
  if (cart?.data?.items?.length) {
    for (const item of cart.data.items) {
      quantity += item.quantity;
    }
  }

  /* ------------------------------------------------------ */
  return (
    <SiteWideContext.Provider
      value={{
        /* AUTH */
        accountDrawerMode,
        accountDrawerUserEmail,
        accountDrawerVisible,
        clearUser,
        intitiateAuthHandoff,
        isLoggedIn,
        loading,
        setAccountDrawerMode,
        setAccountDrawerUserEmail,
        setAccountDrawerVisible,
        setUser,
        user,
        /* SITEWIDE */
        categories: {
          data: categories?.categories ?? [],
          loading: selectedDispensaryLoading || categoriesLoading // categories is only called after fetching selected dispensary
        },
        clearSelectedDispensaryIDForKiosk: clearSelectedDispensaryID,
        isMobile: !!(width && width <= 767),
        isTablet: !!(width && width <= 1024),
        width,
        popup: {
          product: popupProduct,
          setProduct: setPopupProduct,
          setShow: setShowPopup,
          show: showPopup
        },
        selectedDispensary,
        selectedDispensaryID,
        selectedDispensaryLoading,
        setSelectedDispensary,
        setSelectedDispensaryID,
        setUserMenuType,
        setUserOrderType: updateUserOrderTypeLocalStorage,
        userMenuType,
        userOrderType,
        websiteUrl,
        /* CART */
        addItemToCart,
        cart: {
          loading: cart.loading,
          error: cart.error,
          data: cart.data
            ? apolloCartToConsolidateCartMap(cart.data)
            : undefined,
          refetch: cart.refetch
        },
        checkoutId,
        clearAnonCart,
        clearCart,
        mergeCart,
        quantity,
        removeItemFromCart,
        updateCartOrderType
      }}>
      {children}
      {process.env.IS_KIOSK !== 'true' ? (
        <>
          <AuthDrawer
            setVisibility={setAccountDrawerVisible}
            visible={accountDrawerVisible}
          />
          <CookieAcceptanceBanner />
        </>
      ) : null}
    </SiteWideContext.Provider>
  );
};

// Use this in child components
export const useSiteWideContext = () => useContext(SiteWideContext);
